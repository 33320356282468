import { ConnectButton } from '@rainbow-me/rainbowkit'
import Box from 'components/primitives/Box'
import Button from 'components/primitives/Button'
import { FC } from 'react'

type Props = {}

// async function createNeededNetworkInMetamask() {
//   const objParamsMap = {
//     chainId: process.env.NEXT_PUBLIC_CHAIN_ID,
//     rpcUrls: [ process.env.NEXT_PUBLIC_SKALE_URL_HTTP, process.env.NEXT_PUBLIC_SKALE_URL_WS ],
//     chainName: "S-Chain",
//     nativeCurrency: {
//       name: "S-FUEL",
//       symbol: "S-FUEL",
//       decimals: 18
//     },
//     blockExplorerUrls: [ process.env.NEXT_PUBLIC_SKALE_URL_BLOCK_EXPLORER ]
//   };
//   console.log( "Will invoke wallet_addEthereumChain request on Metamask with", objParamsMap, "..." );
//   const provider = window.ethereum;
//   await provider.request({ method: "wallet_addEthereumChain", params: [ objParamsMap ] })
//     .then( ( res ) => { console.log( "Got result of registering S-Chain in Metamask:", res ); })
//     .catch(( err ) => { console.log( "Got error of registering S-Chain: in Metamask", err ); });
// }

// async function checkMetamaskChain() {
//   const provider = window.ethereum;
//   const chainId = await provider.request({ method: "eth_chainId" });
//   if( parseInt(chainId) == parseInt( process.env.NEXT_PUBLIC_CHAIN_ID as string ) )
//     return;
//   await createNeededNetworkInMetamask();
//   try {
//     await provider.request({
//       method: 'wallet_switchEthereumChain',
//       params: [{ chainId: process.env.NEXT_PUBLIC_CHAIN_ID as string }],
//     });
//     console.log("Did successfully switched chain selection in Metamask")
//   } catch ( err ) {
//     if( (err as any).code === 4902 )
//       console.log( "Needed network is not available in metamask, user should manually create it:", err );
//     console.log( "Failed to switch to the selected chain in Metamask:", err  );
//   }
// }

export const ConnectWalletButton: FC<Props> = () => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openConnectModal, mounted }) => {
        return (
          <Box
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'flex',
            }}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <Button
                    css={{ flex: 1, justifyContent: 'center' }}
                    corners="rounded"
                    onClick={
                      
                      openConnectModal
                      
                      // async () => {
                      //   await checkMetamaskChain();
                      //   openConnectModal();
                      // }
                    
                    }
                    type="button"
                  >
                    Connect Wallet
                  </Button>
                )
              }
            })()}
          </Box>
        )
      }}
    </ConnectButton.Custom>
  )
}
